import { MouseEvent, ReactElement, ReactNode, useState } from "react";
import { Link as RouterLink, useMatches } from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  breadcrumbsClasses,
  Button,
  IconButton,
  Link,
  Menu,
  MenuItem,
  styled,
} from "@mui/material";
import { BreadcrumbsProps } from "@mui/material/Breadcrumbs/Breadcrumbs";
import { ManifoldColorBadgeIcon } from "~/assets/icons";
import useUser from "~/contexts/user";
import useAppHeader from "~/contexts/header";
import AccountMenu from "~/components/AccountMenu";
import NavMenu from "~/components/NavMenu";
import TextOverflow from "~/components/TextOverflow";
import ServerHealth from "~/components/ServerHealth";

export const HEADER_HEIGHT = "52px";

interface Breadcrumb {
  title: ReactNode;
  href: string;
}

const StyledBreadcrumbs = styled(Breadcrumbs)(({ theme: { spacing, transitions } }) => ({
  minWidth: 0,
  userSelect: "none",
  marginLeft: spacing(-1),
  overflowX: "auto",
  scrollbarWidth: "thin",
  ol: {
    flexWrap: "nowrap",
  },
  li: {
    fontWeight: 500,
    "a, button": {
      padding: `${spacing(0.25)} ${spacing(0.75)}`,
      margin: `0 ${spacing(0.25)}`,
      bgcolor: "transparent",
      transition: `background ${transitions.duration.shortest}ms ${transitions.easing.easeInOut} 0ms, 
                   opacity ${transitions.duration.shortest}ms ${transitions.easing.easeInOut} 0ms`,
      "&:hover": {
        opacity: 1,
        background: "#E1E1E3",
      },
    },
  },
  [`.${breadcrumbsClasses.separator}`]: {
    fontWeight: 600,
  },
}));

const BreadcrumbCollapse = (props: { expansionItems: Breadcrumb[] }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton size="small" onClick={handleClick}>
        ...
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {props.expansionItems.map((item) => (
          <MenuItem key={item.href} onClick={handleClose} sx={{ p: 0 }}>
            <Link
              underline="none"
              component={RouterLink}
              to={item.href}
              color="inherit"
              sx={{ width: "100%", px: 1.5, py: 0.5 }}
            >
              <TextOverflow display="block" maxWidth={200}>
                {item.title}
              </TextOverflow>
            </Link>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const BreadcrumbItem = (props: Breadcrumb) => (
  <Button component={RouterLink} to={props.href}>
    <TextOverflow autoTooltip maxWidth={300}>
      {props.title}
    </TextOverflow>
  </Button>
);

interface TitleBreadcrumbsProps
  extends Pick<BreadcrumbsProps, "maxItems" | "itemsBeforeCollapse" | "itemsAfterCollapse"> {
  crumbs: Breadcrumb[];
}

export const TitleBreadcrumbs = ({
  crumbs,
  maxItems = Number.MAX_SAFE_INTEGER,
  itemsBeforeCollapse = 1,
  itemsAfterCollapse = 1,
}: TitleBreadcrumbsProps) => {
  const collapsed = crumbs.length > maxItems;
  const crumbsBefore = crumbs.slice(0, collapsed ? itemsBeforeCollapse : undefined);
  const crumbsAfter = crumbs.slice(collapsed ? crumbs.length - itemsAfterCollapse : 0);
  const expansionCrumbs = crumbs.slice(itemsBeforeCollapse, -itemsAfterCollapse);

  const mapCrumb = (crumb: Breadcrumb, last: boolean): ReactElement => {
    if (last) {
      return (
        <TextOverflow
          key={crumb.href}
          autoTooltip
          display="block"
          maxWidth={300}
          fontWeight={600}
          mx={1}
        >
          {crumb.title}
        </TextOverflow>
      );
    }

    return <BreadcrumbItem key={crumb.href} {...crumb} />;
  };

  return (
    <StyledBreadcrumbs
      // Override default behavior of maxItems collapse in favor of custom menu
      maxItems={Number.MAX_SAFE_INTEGER}
    >
      {crumbsBefore.map((crumb, idx) =>
        mapCrumb(crumb, !collapsed && idx === crumbsBefore.length - 1)
      )}
      {collapsed && <BreadcrumbCollapse expansionItems={expansionCrumbs} />}
      {collapsed &&
        crumbsAfter.map((crumb, idx) => mapCrumb(crumb, idx === crumbsAfter.length - 1))}
    </StyledBreadcrumbs>
  );
};

const AppBar = styled("nav")(({ theme: { palette } }) => ({
  width: "100%",
  minHeight: HEADER_HEIGHT,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  color: "inherit",
  borderBottom: `1px solid ${palette.common.toolbarBorder}`,
  backgroundColor: palette.common.toolbarBackground,
}));

const Logo = () => (
  <Link
    to="/"
    component={RouterLink}
    underline="none"
    color="inherit"
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <ManifoldColorBadgeIcon width={24} height={24} />
  </Link>
);

const AppHeader = () => {
  const user = useUser();
  const { title, centerContent } = useAppHeader();
  const matches = useMatches();
  const serverHealthInfo = matches.find(({ handle }) => (handle as any)?.serverHealth);
  const showServerHealth = serverHealthInfo && user?.permissions?.includes("view:sm-server-health");

  return (
    <AppBar as="header" sx={{ position: "sticky", top: 0 }}>
      <Box
        display="flex"
        minWidth={0}
        alignItems="center"
        flex={centerContent ? "1 1 37.5%" : "1 1 auto"}
      >
        <NavMenu />
        <Logo />
        <Box
          sx={{
            userSelect: "none",
            pl: 2,
            fontSize: 14,
            fontWeight: 600,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {title}
        </Box>
      </Box>
      {centerContent && (
        <Box flex="1 1 25%" minWidth={0} justifyContent="center">
          {centerContent}
        </Box>
      )}
      <Box display="flex" flex={centerContent ? "1 1 37.5%" : "1 1 auto"} pr={1.5}>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 1,
          }}
        >
          {showServerHealth && (
            <ServerHealth serverEndpoints={(serverHealthInfo.handle as any)?.serverHealth} />
          )}
          <AccountMenu />
        </Box>
      </Box>
    </AppBar>
  );
};

export default AppHeader;

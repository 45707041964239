import yn from "yn";
import { PropsWithChildren } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Button, Container, Link, Skeleton, Stack, Typography as T } from "@mui/material";
import {
  CampaignToolIcon,
  ParticipantSearchToolIcon,
  PlusIcon,
  SurveyToolIcon,
  TaskToolIcon,
} from "~/assets/icons";
import useUser from "~/contexts/user";
import useAppHeader from "~/contexts/header";
import { useDocumentTitle } from "~/hooks/useDocumentTitle";
import InteractiveCard, { IconContainer } from "~/components/InteractiveCard";
import { DataBrowserSections } from "~/pages/DataBrowser";
import useProjects from "rwb/hooks/useProjects";
import CreateProject from "rwb/pages/projects/components/CreateProject";
import ProjectList from "rwb/pages/projects/components/ProjectList";
import CardGrid from "~/components/CardGrid";

const { VITE_RWB_ENABLED, VITE_SM_ENABLED } = import.meta.env;

const Section = (props: { title: string; actions?: any } & PropsWithChildren) => (
  <Box
    component="section"
    sx={{
      pt: 2,
      mb: 6,
    }}
  >
    <Container>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <T variant="h6" fontSize={[18, 20]} fontWeight={550}>
          {props.title}
        </T>
        {props.actions && props.actions}
      </Box>
      {props.children}
    </Container>
  </Box>
);

const PROJECTS_LIMIT = 4;

const WorkbenchWidget = () => {
  const projects = useProjects(PROJECTS_LIMIT);
  const loading = projects.isLoading;
  const empty = !loading && !projects.data?.total;

  return (
    <Section
      title="Workbench"
      actions={
        !empty && (
          <Box display="flex" gap={1}>
            {loading ? (
              <>
                <Skeleton variant="rounded" width={100} height={35} />
                <Skeleton variant="rounded" width={120} height={35} />
              </>
            ) : (
              <>
                {Number(projects.data?.total) > PROJECTS_LIMIT && (
                  <Button
                    variant="text"
                    sx={{ px: 1.5, py: 1 }}
                    component={RouterLink}
                    to="/projects"
                  >
                    All Projects
                  </Button>
                )}
                <CreateProject
                  callToAction={
                    <Button variant="text" sx={{ px: 1.5, py: 1 }} startIcon={<PlusIcon />}>
                      New Project
                    </Button>
                  }
                />
              </>
            )}
          </Box>
        )
      }
    >
      <Box mt={2}>
        <ProjectList data={projects} pageSize={PROJECTS_LIMIT} showPagination={false} />
      </Box>
    </Section>
  );
};

const DataBrowserWidget = () => {
  return (
    <Section title="Data Browser">
      <Box pt={2}>
        <DataBrowserSections embedded />
      </Box>
    </Section>
  );
};

const StudyManagementWidget = () => {
  const links = [
    {
      url: "/sm/participants",
      icon: <ParticipantSearchToolIcon />,
      title: "Search Participants",
      description:
        "Look up participants to view detailed information about their demographics and study participation.",
    },
    {
      url: "/sm/surveys",
      icon: <SurveyToolIcon />,
      title: "Manage Surveys",
      description: "Build a survey to send to participants.",
    },
    {
      url: "/sm/outreach",
      icon: <CampaignToolIcon />,
      title: "Contact Participants",
      description: "Send surveys or other study-related information to a group of participants.",
    },
    {
      url: "/sm/tasks",
      icon: <TaskToolIcon />,
      title: "Manage Tasks",
      description:
        "Manage tasks related to participants' self-reported cancers, revocations or specific study information.",
    },
  ];

  return (
    <Section title="Study Management">
      <CardGrid sx={{ mt: 2 }}>
        {links.map((link) => (
          <Link key={link.url} component={RouterLink} to={link.url} underline="none">
            <InteractiveCard
              sx={{
                px: 3,
                py: 2.5,
                height: 200,
                justifyContent: "space-between",
              }}
            >
              <IconContainer color="primary.main">{link.icon}</IconContainer>
              <T variant="body1" fontWeight={500} my={1.25}>
                {link.title}
              </T>
              <T
                variant="body2"
                sx={{
                  opacity: 0.7,
                  flex: 1,
                  overflow: "hidden",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: "4",
                }}
              >
                {link.description}
              </T>
            </InteractiveCard>
          </Link>
        ))}
      </CardGrid>
    </Section>
  );
};

const title = "Home";

const Home = () => {
  const user = useUser();
  useDocumentTitle(title);
  useAppHeader({ title });

  const showDb = !!user;
  const showRwb = yn(VITE_RWB_ENABLED) && user?.permissions?.includes("view:workbench-app");
  const showSm = yn(VITE_SM_ENABLED) && user?.permissions?.includes("view:sm-app");

  return (
    <Stack bgcolor="common.dimmedPageBackground" minHeight="100%">
      {showSm && <StudyManagementWidget />}
      {showRwb && <WorkbenchWidget />}
      {showDb && <DataBrowserWidget />}
    </Stack>
  );
};

export default Home;

import yn from "yn";
import { PropsWithChildren, Suspense, useEffect, useLayoutEffect, useRef } from "react";
import { Navigate, RouteObject, useLocation } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import {
  Home,
  NotFound,
  PrivacyPolicy,
  Dashboards,
  DataBrowser,
  DataOverview,
  Files,
  SmPortal,
  ResearchWorkbench,
  CohortExplorer,
  DataCatalog,
  Admin,
  Cohorts,
  Report,
} from "~/pages";
import { ProtectedAppRoute } from "./components/ProtectedAppRoute";
import { Box } from "@mui/material";
import redirects from "./redirects";
import AppHeader, { HEADER_HEIGHT } from "~/components/AppHeader";
import Loading from "~/components/Loading";
import useAppHeader from "~/contexts/header";

interface AppLayoutProps extends PropsWithChildren {
  enabled?: boolean;
}

const {
  VITE_RWB_ENABLED,
  VITE_CATALOG_ENABLED,
  VITE_SM_ENABLED,
  VITE_OUTREACH_API,
  VITE_PARTICIPANT_API,
  VITE_SURVEY_API,
  VITE_SUPERSET_ENABLED,
  VITE_DATA_DASHBOARD_URL,
  VITE_ADMIN_APP_ENABLED,
  VITE_COHORT_EXPLORER_ENABLED,
  VITE_STREAMLIT_COHORT_BUILDER_ENABLED,
  VITE_SIGMA_REPORT_IDS,
} = import.meta.env;

/**
 * Top-level app layout
 * @param enabled - Build-time, env specific feature flag support
 * @param children
 * @constructor
 */
const AppLayout = ({ enabled = true, children }: AppLayoutProps) => {
  const location = useLocation();
  const wrapperRef = useRef<HTMLElement>(null);
  const { setCenterContent } = useAppHeader();
  const contentHeight = `calc(100vh - ${HEADER_HEIGHT})`;

  useEffect(() => {
    // Handle resetting scroll on navigation
    wrapperRef.current?.scrollTo({ top: 0 });
  }, [wrapperRef.current, location.pathname]);

  useLayoutEffect(() => {
    // Ugly hack to avoid flicker between tab switches on participant profile
    // TODO: Deprecate in favor of a more generic solution for multi-page center content
    if (!/^\/sm\/participants\/\d+.*$/.test(location.pathname)) {
      // Reset page specific center content when navigating
      setCenterContent(null);
    }
  }, [location.pathname, setCenterContent]);

  if (!enabled) {
    return <Navigate to="/404" replace />;
  }

  return (
    <QueryParamProvider adapter={ReactRouter6Adapter} options={{ removeDefaultsFromUrl: true }}>
      <AppHeader />
      <Box
        component="main"
        ref={wrapperRef}
        sx={{
          position: "relative",
          height: contentHeight,
          overflow: "auto",
          bgcolor: "common.pageBackground",
        }}
      >
        {children}
      </Box>
    </QueryParamProvider>
  );
};

/**
 * Wrapper for lazy imported apps
 * @param children - Route content which might also include its own sub-router
 * @constructor
 */
const LazyWrapper = ({ children }: PropsWithChildren) => (
  <Suspense fallback={<Loading />}>{children}</Suspense>
);

const routes: RouteObject[] = [
  {
    index: true,
    element: (
      <AppLayout>
        <Home />
      </AppLayout>
    ),
  },
  ...redirects,
  {
    path: "files/*",
    element: (
      <AppLayout>
        <LazyWrapper>
          <Files />
        </LazyWrapper>
      </AppLayout>
    ),
  },
  {
    path: "cohorts/*",
    element: (
      <AppLayout enabled={yn(VITE_COHORT_EXPLORER_ENABLED, { default: false })}>
        <LazyWrapper>
          <ProtectedAppRoute permission="view:cohort-explorer-rows">
            <Cohorts />
          </ProtectedAppRoute>
        </LazyWrapper>
      </AppLayout>
    ),
  },
  {
    path: "projects/*",
    element: (
      <AppLayout enabled={yn(VITE_RWB_ENABLED, { default: false })}>
        <LazyWrapper>
          <ProtectedAppRoute permission="view:workbench-app">
            <ResearchWorkbench />
          </ProtectedAppRoute>
        </LazyWrapper>
      </AppLayout>
    ),
  },
  {
    path: "catalog/*",
    element: (
      <AppLayout enabled={yn(VITE_CATALOG_ENABLED, { default: false })}>
        <LazyWrapper>
          <ProtectedAppRoute permission="view:catalog-app">
            <DataCatalog />
          </ProtectedAppRoute>
        </LazyWrapper>
      </AppLayout>
    ),
  },
  {
    path: "dashboards",
    element: (
      <AppLayout enabled={yn(VITE_SUPERSET_ENABLED, { default: false })}>
        <ProtectedAppRoute permission="view:workbench-dashboards">
          <Dashboards />
        </ProtectedAppRoute>
      </AppLayout>
    ),
  },
  {
    path: "data-browser",
    element: (
      <AppLayout>
        <DataBrowser />
      </AppLayout>
    ),
  },
  {
    path: "data-overview",
    element: (
      <AppLayout enabled={!!VITE_DATA_DASHBOARD_URL}>
        <DataOverview />
      </AppLayout>
    ),
  },
  {
    path: "reports/:reportType",
    element: (
      <AppLayout enabled={!!VITE_SIGMA_REPORT_IDS}>
        <Report />
      </AppLayout>
    ),
  },
  {
    path: "cohort-explorer",
    element: (
      <AppLayout enabled={yn(VITE_STREAMLIT_COHORT_BUILDER_ENABLED, { default: false })}>
        <CohortExplorer />
      </AppLayout>
    ),
  },
  {
    path: "sm/*",
    element: (
      <AppLayout enabled={yn(VITE_SM_ENABLED, { default: false })}>
        <LazyWrapper>
          <ProtectedAppRoute permission="view:sm-app">
            <SmPortal />
          </ProtectedAppRoute>
        </LazyWrapper>
      </AppLayout>
    ),
    handle: {
      serverHealth: [
        {
          endpoint: VITE_OUTREACH_API,
          label: "Outreach Service",
        },
        {
          endpoint: VITE_PARTICIPANT_API,
          label: "Participant Service",
        },
        {
          endpoint: VITE_SURVEY_API,
          label: "Survey Service",
        },
      ],
    },
  },
  {
    path: "admin/*",
    element: (
      <AppLayout enabled={yn(VITE_ADMIN_APP_ENABLED, { default: false })}>
        <LazyWrapper>
          <ProtectedAppRoute permission="view:admin-app">
            <Admin />
          </ProtectedAppRoute>
        </LazyWrapper>
      </AppLayout>
    ),
  },
  { path: "privacy", element: <PrivacyPolicy /> },
  { path: "404", element: <NotFound /> },
  { path: "*", element: <Navigate to="/404" replace /> },
];

export default routes;

import { Box, BoxProps } from "@mui/material";

const CardGrid = ({ sx, ...rest }: BoxProps) => (
  <Box
    sx={{
      display: "grid",
      gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
      gap: "16px",
      ...sx,
    }}
    {...rest}
  />
);

export default CardGrid;

import { useMemo } from "react";
import useConceptsTree from "catalog/hooks/useConceptsTree";
import useConceptsLookup from "catalog/hooks/useConceptsLookup";
import { aggregateChildren } from "catalog/utils/concept";
import ConceptCategory from "catalog/types/enums/conceptCategory";
import ConceptEntity from "catalog/types/enums/conceptEntity";

interface CategoryInfo {
  category: ConceptCategory;
  counts: { type: ConceptEntity; count: number }[];
}

const useConceptCategories = (): { loading: boolean; data?: CategoryInfo[]; error?: any } => {
  const { data: tree, isLoading: loadingTree, error: treeError } = useConceptsTree();
  const { data: lookup, isLoading: loadingLookup, error: lookupError } = useConceptsLookup();
  const data = useMemo(() => {
    if (tree?.data && lookup?.data) {
      return Object.entries(tree.data).map(([category, node]) => {
        const childCounts = aggregateChildren(node?.children, lookup.data);
        return {
          category: category as ConceptCategory,
          counts: childCounts,
        };
      });
    }
  }, [tree, lookup]);

  return { data, loading: loadingTree || loadingLookup, error: treeError ?? lookupError };
};

export default useConceptCategories;

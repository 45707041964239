import React, {
  createContext,
  PropsWithChildren,
  SyntheticEvent,
  useCallback,
  useContext,
  useState,
} from "react";
import { Alert, Slide, Snackbar } from "@mui/material";
import { SnackbarCloseReason } from "@mui/material/Snackbar/Snackbar";
import { TransitionProps } from "@mui/material/transitions";

const ErrorToastContext = createContext<{ showError: (message: string) => void }>({
  showError: () => {},
});

const TransitionDown = (props: TransitionProps & { children: React.ReactElement<any, any> }) => {
  return (
    <Slide {...props} direction="down">
      {props.children}
    </Slide>
  );
};

export const ErrorToastProvider = ({ children }: PropsWithChildren) => {
  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  const showError = (message: string) => {
    setMessage(message);
    setOpen(true);
  };

  const handleClose = useCallback((_: SyntheticEvent | Event, reason: SnackbarCloseReason) => {
    if (reason !== "clickaway") {
      setOpen(false);
    }
  }, []);

  return (
    <ErrorToastContext.Provider value={{ showError }}>
      {children}
      <Snackbar
        open={open}
        autoHideDuration={3500}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        TransitionComponent={TransitionDown}
      >
        <Alert severity="error" sx={{ border: ({ palette }) => `1px solid ${palette.error.main}` }}>
          {message}
        </Alert>
      </Snackbar>
    </ErrorToastContext.Provider>
  );
};

const useErrorToast = () => {
  return useContext(ErrorToastContext);
};

export default useErrorToast;

import { lazy } from "react";

export { default as Home } from "./Home";
export { default as DataBrowser } from "./DataBrowser";
export { default as PrivacyPolicy } from "./PrivacyPolicy";
export { default as NotFound } from "./NotFound";
export { default as DataOverview } from "./DataOverview";
export { default as Dashboards } from "./Dashboards";
export { default as CohortExplorer } from "./StreamlitCohortExplorer";
export { default as Report } from "./Report";

// Lazy loaded pages (note: must be wrapped in suspense)
export const Files = lazy(() => import("files/GlobalFilesView"));
export const SmPortal = lazy(() => import("sm/StudyManagement"));
export const ResearchWorkbench = lazy(() => import("rwb/ResearchWorkbench"));
export const DataCatalog = lazy(() => import("catalog/DataCatalog"));
export const Cohorts = lazy(() => import("cohorts/Cohorts"));
export const Admin = lazy(() => import("admin/Admin"));

import { Box, styled } from "@mui/material";
import FlatCard from "~/components/FlatCard";

export const IconContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: 30,
  height: 30,
});

const InteractiveCard = styled(FlatCard)(({ theme }) => ({
  width: "100%",
  position: "relative",
  display: "flex",
  overflow: "visible",
  flexDirection: "column",
  borderRadius: 10,
  backgroundColor: theme.palette.common.white,
  transition: "all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1)",
  "&:hover": {
    cursor: "pointer",
    zIndex: 1,
    transform: "scale(1.02, 1.02)",
  },
  "&:after": {
    content: "''",
    borderRadius: "10px",
    position: "absolute",
    zIndex: -1,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    opacity: 0,
    boxShadow: "0px 1px 6px 0px rgba(0, 0, 0, 0.16)",
    transition: "all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1)",
  },
  "&:hover:after": {
    opacity: 1,
  },
  "&[aria-disabled=true]": {
    pointerEvents: "none",
  },
}));

export default InteractiveCard;

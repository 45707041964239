import { ComponentProps, FunctionComponent } from "react";
import { BiospecimenIcon, DataOverview, GenomicsIcon, TissueSampleIcon } from "~/assets/icons";

enum Report {
  OVERVIEW = "overview",
  TISSUE = "tissue",
  BLOOD = "blood",
  GENOMICS = "genomics",
}

interface ReportDisplay {
  ids: string[];
  label: string;
  description: string;
  icon: FunctionComponent<ComponentProps<"svg">>;
  iconColor: string;
}

export const DISPLAY_REPORT: Record<Report, ReportDisplay> = {
  [Report.OVERVIEW]: {
    ids: ["Overview-Report-2z3oTQpFRxQ9Uv6XbEpwJJ", "Overview-Report-12tSRUxf6QMjDW7Ldak26U"],
    label: "Overview",
    description: "Start here to get an overview of data in the platform",
    icon: DataOverview,
    iconColor: "#2225F5",
  },
  [Report.TISSUE]: {
    ids: [
      "Tissue-Biospecimen-Report-5lrDQSwvlsniLhbAL8i3Gi",
      "Tissue-Biospecimens-6rsuqelk2578C3RgImZEZl",
    ],
    label: "Tissue Availability",
    description: "Overview of solid specimens",
    icon: TissueSampleIcon,
    iconColor: "#FF4C1A",
  },
  [Report.BLOOD]: {
    ids: [
      "Blood-Biospecimen-Report-64hyi9zbwmLFpDIsZwbcxw",
      "Blood-Biospecimens-4o35LzJyGVnHxLlaXQIlvh",
    ],
    label: "Blood Availability",
    description: "Overview of blood specimens",
    icon: BiospecimenIcon,
    iconColor: "#E14B4B",
  },
  [Report.GENOMICS]: {
    ids: ["Genomics-Report-1Q2Lz0rQH0OuL09MpGuWwG", "Genomics-Report-DKS1yk3P4skfICdC0VcZe"],
    label: "Genomics",
    description: "Overview of genomics sequencing files and indexed variants",
    icon: GenomicsIcon,
    iconColor: "#651EFE",
  },
};

export default Report;

import { ReactElement, useEffect, useState } from "react";
import { Box, Button, Menu, styled } from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import noop from "~/utils/noop";
import LoadingOverlay from "~/components/LoadingOverlay";
import useAppHeader from "~/contexts/header";
import TextOverflow from "~/components/TextOverflow";

interface AppContainerProps {
  iframeSrc?: string;
  loading?: boolean;
  messageHandler?: (event: MessageEvent) => void;
  ToolbarProps?: {
    loading?: boolean;
    title?: string;
    icon?: ReactElement;
    menuOptions?: ReactElement[];
  };
}

const StyledIcon = styled(Box)({ display: "flex", svg: { width: 16 } });

const MenuTitle = ({
  icon,
  title,
  menuOptions,
}: {
  icon?: ReactElement;
  title?: string;
  menuOptions?: ReactElement[];
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  return (
    <>
      <Button
        id="more-actions-btn"
        aria-controls={anchorEl ? "more-actions-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={anchorEl ? "true" : undefined}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        startIcon={<StyledIcon>{icon}</StyledIcon>}
        endIcon={<ExpandMore />}
        sx={{
          width: "100%",
          minWidth: 0,
          maxWidth: "fit-content",
          color: "common.black",
          fontWeight: 400,
          px: 1.5,
          "&:hover": {
            background: "rgba(255, 255, 255, 0.16)",
          },
          "& .MuiButton-endIcon": {
            "& svg": {
              width: 14,
            },
            marginLeft: 0.25,
          },
        }}
      >
        <TextOverflow variant="inherit" maxWidth={[200, 350]}>
          {title}
        </TextOverflow>
      </Button>
      <Menu
        id="more-actions-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClick={() => setAnchorEl(null)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        MenuListProps={{
          "aria-labelledby": "more-actions-btn",
        }}
      >
        {menuOptions}
      </Menu>
    </>
  );
};

const AppToolbar = ({
  title,
  icon,
  loading,
  menuOptions,
}: NonNullable<AppContainerProps["ToolbarProps"]>) => {
  /**
   * Apps without menu options shouldn't display a toolbar. No need to reiterate
   * the icon/name as it's already visible in the project side nav.
   */
  if (!menuOptions || loading) {
    return null;
  }

  return (
    <Box display="flex" px={1.5} justifyContent="center" alignItems="center" color="common.white">
      <MenuTitle icon={icon} title={title} menuOptions={menuOptions} />
    </Box>
  );
};

const AppContainer = ({
  iframeSrc,
  loading,
  messageHandler = noop,
  ToolbarProps,
}: AppContainerProps) => {
  const { setCenterContent } = useAppHeader();
  const [iframeLoading, setIframeLoading] = useState(true);

  useEffect(() => {
    if (ToolbarProps) {
      setCenterContent(<AppToolbar loading={loading} {...ToolbarProps} />);
    }
  }, [loading]);

  useEffect(() => {
    window.addEventListener("message", messageHandler);
    return () => window.removeEventListener("message", messageHandler);
  }, [messageHandler]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          position: "relative",
          userSelect: "none",
          overflow: "hidden",
        }}
      >
        <LoadingOverlay when={loading || iframeLoading} />
        <Box
          key={iframeSrc}
          component="iframe"
          src={iframeSrc}
          sandbox="allow-same-origin allow-scripts allow-downloads allow-modals allow-forms allow-popups"
          onLoad={() => setIframeLoading(false)}
          width="100%"
          height="100%"
          border="none"
        />
      </Box>
    </>
  );
};

export default AppContainer;

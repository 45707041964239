import { styled, Typography as T } from "@mui/material";

const ClampedTypography = styled(T)(() => ({
  overflow: "hidden",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: "2",
}));

export default ClampedTypography;

import { HTMLAttributes, ReactElement } from "react";
import { NavLink, Path, To, useLocation } from "react-router-dom";
import {
  Box,
  Divider as MuiDivider,
  Link,
  LinkTypeMap,
  List,
  Stack,
  styled,
  Tooltip,
  Typography as T,
  useTheme,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { HEADER_HEIGHT } from "~/components/AppHeader";

export const NAV_WIDTH = "57px";

interface NavItemProps {
  title: string;
  to: To;
  icon: ReactElement;
  showTitle?: boolean;
  tooltip?: ReactElement | string;
  sx?: any;
}

const StyledLink = styled(Link)({
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
  svg: {
    height: 24,
    width: 24,
  },
  "& div": {
    backgroundColor: "transparent",
    transition: "background-color .2s linear",
  },
  "&:hover div": {
    backgroundColor: "#e4e4e6",
  },
  "&.active div": {
    backgroundColor: "#E8E5FF",
    "svg, span": {
      color: "#5030c3",
      fontWeight: 500,
    },
  },
}) as OverridableComponent<LinkTypeMap>;

export const NavItem = ({ title, to, icon, showTitle, tooltip, sx = {} }: NavItemProps) => {
  const { pathname } = useLocation();
  const overrideHighlight =
    typeof to === "string" ? to.startsWith(pathname) : (to as Path).pathname.startsWith(pathname);

  return (
    <Tooltip title={tooltip} enterDelay={500} placement="right" arrow disableInteractive>
      <Box component="li" display="flex" width="100%">
        <StyledLink
          component={NavLink}
          to={to}
          className={overrideHighlight ? "active" : undefined}
          sx={{ display: "block", width: "100%", ...sx }}
          aria-label={title}
          underline="none"
        >
          <Box
            display="flex"
            gap={1}
            px={showTitle ? 1.5 : 0}
            height={40}
            minWidth={40}
            alignItems="center"
            whiteSpace="nowrap"
            justifyContent={showTitle ? "left" : "center"}
            borderRadius="6px"
            color="primary.dark"
          >
            {icon}
            {showTitle && (
              <T component="span" variant="body2">
                {title}
              </T>
            )}
          </Box>
        </StyledLink>
      </Box>
    </Tooltip>
  );
};

const Divider = () => (
  <MuiDivider
    flexItem
    sx={{ bgcolor: "primary.dark", opacity: 0.2, width: 30, mx: "auto", my: 0.25 }}
  />
);

const SideNav = ({
  sections,
  className,
}: HTMLAttributes<HTMLElement> & {
  sections: (ReactElement<NavItemProps>[] | ReactElement<NavItemProps>)[];
}) => {
  const theme = useTheme();
  return (
    <Box
      className={className}
      component="nav"
      sx={{
        py: 1.5,
        top: 0,
        left: 0,
        position: "sticky",
        zIndex: theme.zIndex.appBar,
        flex: `0 0 ${NAV_WIDTH}`,
        height: `calc(100vh - ${HEADER_HEIGHT})`,
        bgcolor: "common.toolbarBackground",
        overflowX: "hidden",
        overflowY: "auto",
        borderRight: `1px solid ${theme.palette.common.toolbarBorder}`,
      }}
    >
      <Stack component={List} disablePadding width="100%" gap={0.75} px={1}>
        {sections.flatMap((section, index) => {
          const last = index === sections.length - 1;

          if (!last) {
            return [section, <Divider key={`divider-${index}`} />];
          }

          return [section];
        })}
      </Stack>
    </Box>
  );
};

export default SideNav;

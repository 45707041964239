import { useMemo } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { intersection } from "ramda";
import ReportType, { DISPLAY_REPORT } from "~/types/enums/report";
import useAppHeader from "~/contexts/header";
import SigmaEmbed from "~/components/SigmaEmbed";
import { TitleBreadcrumbs } from "~/components/AppHeader";

const { VITE_SIGMA_REPORT_IDS, VITE_SIGMA_URL } = import.meta.env;

const Report = () => {
  const location = useLocation();
  const { reportType } = useParams();
  const mapping = DISPLAY_REPORT[reportType as ReportType];
  useAppHeader({
    title: (
      <TitleBreadcrumbs
        crumbs={[
          { title: "Data Browser", href: "/data-browser" },
          { title: mapping?.label, href: location.pathname },
        ]}
      />
    ),
  });

  const workbookId = useMemo(() => {
    if (mapping && VITE_SIGMA_REPORT_IDS) {
      const configuredIds = VITE_SIGMA_REPORT_IDS.split(",");
      const overlap = intersection(configuredIds, mapping.ids);

      if (overlap.length > 1) {
        console.warn("Multiple reports of same type configured");
      }

      // Get ID that exists in customer environment config for a given report definition
      return overlap[0];
    }

    return null;
  }, [reportType]);

  if (!workbookId) {
    return <Navigate to="/404" replace />;
  }

  return <SigmaEmbed workbookUrl={`${VITE_SIGMA_URL}/manifold/workbook/${workbookId}`} />;
};

export default Report;
